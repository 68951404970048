<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <router-link class="navbar-brand" :to="{ name: 'Home' }">
      <img id="logo" src="../assets/logo1.png" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
    <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <form class="form-inline ml-auto mr-auto">
        <div class="input-group">
          <input
            size="100"
            type="text"
            class="form-control"
            placeholder="Search Items"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
          <div class="input-group-prepend">
            <span class="input-group-text" id="search-button-navbar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                />
              </svg>
            </span>
          </div>
        </div>
      </form>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link text-light dropdown-toggle" href="#" id="navbarAccount" data-toggle="dropdown">Browse</a>
          <div class="dropdown-menu" aria-labelledby="navbarAccount">
            <router-link class="dropdown-item" :to="{ name : 'Home' }" style="color: black">Home</router-link>
            <router-link class="dropdown-item" :to="{ name : 'Product' }" style="color: black">Product</router-link>
            <router-link class="dropdown-item" :to="{ name : 'Category' }" style="color: black">Category</router-link>
          </div>  
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link text-light dropdown-toggle" href="#" id="navbarAccount" data-toggle="dropdown">Account</a>
          <div class="dropdown-menu" aria-labelledby="navbarAccount">
            <router-link class="dropdown-item" v-if="token" :to="{ name : 'ShowWishlist' }" style="color: black">Wishlist</router-link>
            <router-link class="dropdown-item" v-if="!token" :to="{ name : 'Signup' }" style="color: black">Sign up</router-link>
            <router-link class="dropdown-item" v-if="!token" :to="{ name : 'Signin' }" style="color: black">Sign in</router-link>
            <a class="dropdown-item" v-if="token" href="#" @click="signout" style="color: black">Sign out</a>
          </div>
        </li>
        <li class="nav-item">
          <div id="cart" style="position:relative">
            <span v-if="cardCount !== 0" id="nav-cart-count">{{ cardCount }}</span>
            <router-link class="text-light pl-2" :to="{name: 'Card'}">
              <i class="fa fa-shopping-cart" style="font-size: 36px"></i>
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

import swal from 'sweetalert'

export default {
  name: "Navbar",
  props: ["cardCount"],
  data() {
    return {
      token: null
    }  
  },
  methods: {
    signout() {
      localStorage.removeItem("token");
      this.token = null;
      swal({
        text: 'Buy Buy. See you later =)',
        icon: 'success'
      })
      this.$emit("resetCardCount");
      this.$router.push({name: "Home"})
    }
  },
  mounted() {
    this.token = localStorage.getItem("token");
  }
};
</script>

<style scoped>

#logo {
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
}
a {
  color: white;
}
.nav-link {
  color: white;
}
#search-button-navbar {
  background-color: white;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
#nav-cart-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  font-size: 15px;
  margin-left: 10px;
}

</style>