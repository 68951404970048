<template>
    <footer>
        <div class="container pt-5">
            <div class="row">
                <div class="col-md-3 col-6">
                    <ul style="list-style-type: none">
                        <li class="text-light font-weigh-bold pb-2">
                            Get to know us
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                About us
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                Android App
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                iOS App
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-6">
                    <ul style="list-style-type: none">
                        <li class="text-light font-weigh-bold pb-2">
                            Connect us
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                About us
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                Android App
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                iOS App
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-6">
                    <ul style="list-style-type: none">
                        <li class="text-light font-weigh-bold pb-2">
                            Let us help
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                About us
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                Android App
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                iOS App
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-6">
                    <ul style="list-style-type: none">
                        <li class="text-light font-weigh-bold pb-2">
                            Money with us
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                About us
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                Android App
                            </a>
                        </li>
                        <li>
                            <a class="footer-link font-weight-light" href="#">
                                iOS App
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {

}

</script>

<style>

    footer {
        margin-top: 100px;
        background-color: #232F3E;
        font-size: 16px;
    }
    a {
        text-decoration: aliceblue;
    }
    .footer-link {
        color: #DDDDDD;
    }
    li {
        padding-bottom: 10px;
    }

</style>